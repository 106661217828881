import "./products.scss";

import {
  Add as AddIcon,
  Clear,
  ClearAll,
  EditNote,
  MeetingRoom,
  RemoveCircleOutline,
} from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Pagination,
  Stack,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import Tenant, { TenantCreateInput } from "../../api/models/Tenant.model";
import Unit, { UnitCreateInput } from "../../api/models/Unit.model";
import { useCallback, useState } from "react";
import { useOutletContext, useParams } from "react-router";

import Api from "../../api/Api";
import CreateMeterDialog from "../../components/dialog/createMeterDialog";
import CreateTenantDialog from "../../components/dialog/createTenantDialog";
import CreateUnitDialog from "../../components/dialog/createUnitDialog";
import DeleteButton from "../../components/deleteButton/DeleteButton";
import EditIcon from "@mui/icons-material/Edit";
import Meter from "../../api/models/Meter.model";
import Property from "../../api/models/Property.model";
import UnitMeters from "./UnitMeters";
import { useMeters } from "../../contexts/meters/useMeters";
import { useUnits } from "../../contexts/UnitsContext";

export default function Units() {
  // const { property } = useOutletContext<{ property: Property }>();
  const { propertyId } = useParams();
  const { units, updateUnits, updateUnitTenants, handleTenantAction } =
    useUnits();
  const [currentUnit, setCurrentUnit] = useState<Unit | undefined>();
  const [currentTenant, setCurrentTenant] = useState<Tenant | undefined>();

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openTenantDialog, setOpenTenantDialog] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState(0);

  const {
    currentMeter,
    openMeterDialog,
    openMeterDialogHandler,
    handleDeleteMeter,
    closeMeterDialogHandler,
    submitMeterDialogHandler,
  } = useMeters(currentUnit, setCurrentUnit);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const onDialogSubmit = useCallback(
    (data: UnitCreateInput) => {
      if (data.id) {
        const updateData = {
          // TODO Map other properties of Unit for saving later
          name: data.name,
        };
        updateCurrentUnit(updateData, data.id);
      } else {
        const newData = {
          name: data.name,
          // id: data.id, // ???
        };
        addNew(propertyId as string, newData);
      }
    },
    [updateUnits, propertyId]
  );

  const updateCurrentUnit = (newProperty: any, id: string) => {
    Api.updateUnit(newProperty, id).then(() => {
      updateUnits();
    });
  };

  const addNew = (propertyId: string, newUnit: any) => {
    Api.postPropertyUnit(propertyId, newUnit).then(() => {
      updateUnits();
    });
  };

  const updateUnit = useCallback((unit: Unit) => {
    setCurrentUnit(unit);
    setOpenDialog(true);
  }, []);

  const onAddButtonClick = useCallback(() => {
    setCurrentUnit(undefined);
    setOpenDialog(true);
  }, []);

  const onDialogClose = useCallback(() => {
    setOpenDialog(false);
  }, []);

  const onUnitDelete = (unit: Unit) => {
    Api.deleteUnit(unit).then(() => {
      updateUnits();
    });
  };

  /****** tenants */
  const onAddTenantButtonClick = useCallback((unit: Unit) => {
    setCurrentUnit(unit);
    setCurrentTenant(undefined);
    setOpenTenantDialog(true);
  }, []);

  const onUpdateTenantButtonClick = useCallback(
    (unit: Unit, tenant: Tenant) => {
      setCurrentUnit(unit);
      setCurrentTenant(tenant);
      setOpenTenantDialog(true);
    },
    []
  );

  const onTenantDialogClose = useCallback(() => {
    setOpenTenantDialog(false);
  }, []);

  const onTenantDialogSubmit = useCallback(
    (data: TenantCreateInput) => {
      if (data.id) {
        let updateTenant = new Tenant(data.id, data.name as string);

        updateTenant = { ...updateTenant, ...data }; // update all props

        patchTenant(updateTenant);
      } else {
        const newData = {
          name: data.name,
          // id: data.id, // ???
        };
        addNewTenant(newData);
      }
    },
    [handleTenantAction, currentUnit, propertyId]
  );

  const patchTenant = (updateTenant: Tenant) => {
    if (!currentUnit) {
      return;
    }

    Api.updateTenant(updateTenant, updateTenant.id).then(
      (updatedTenant: Tenant) => {
        handleTenantAction(currentUnit, updatedTenant, "update");
      }
    );
  };

  const addNewTenant = (newTenant: TenantCreateInput) => {
    if (!currentUnit || !propertyId) {
      return;
    }

    newTenant.propertyId = propertyId;
    Api.postUnitTenant(currentUnit.id, newTenant).then(
      (createdTenant: Tenant) => {
        handleTenantAction(currentUnit, createdTenant, "add");
      }
    );
  };

  const onDeleteTenantButtonClick = (tenant: Tenant, unit: Unit) => {
    Api.deleteTenant(tenant).then(() => {
      updateUnitTenants(unit);
    });
  };

  // #### TABs Stuff

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  return (
    <>
      <div className="contentTable">
        <div className="header">
          <div className="cell1">
            <MeetingRoom id="categoryIcon" />
            Nutzeinheiten
          </div>
          <Button
            className="newStorageUnitBtn"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => onAddButtonClick()}
          >
            Neue Nutzeinheit hinzufügen
          </Button>
        </div>
        <Box sx={{ padding: "1rem", color: "dimgray" }}>
          Hier können Sie Nutzeinheiten und deren Mieter anlegen. Auch die
          Zähler können hier einer Nutzeinheit zugeordnet werden
        </Box>
        <div className="table">
          <CreateUnitDialog
            open={openDialog}
            onClose={onDialogClose}
            onSubmit={onDialogSubmit}
            unit={currentUnit}
          />

          <CreateTenantDialog
            open={openTenantDialog}
            onClose={onTenantDialogClose}
            onSubmit={onTenantDialogSubmit}
            tenant={currentTenant}
          />

          <CreateMeterDialog
            open={openMeterDialog}
            onClose={closeMeterDialogHandler}
            onSubmit={submitMeterDialogHandler}
            meter={currentMeter}
          />

          <div className="note">
            - Geräte Tab: Zuordnung von unter Hardware angelegten Zähler (Gas,
            wasser strom), Rauchwarnmelder etc.{" "}
          </div>

          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              sx={{
                "& .MuiTab-root": {
                  color: "#5f3d3d", // Default tab text color
                  "&.Mui-selected": {
                    color: "#5f3d3d", // Selected tab text color
                  },
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "#5f3d3d", // Indicator color
                },
              }}
            >
              <Tab label="Mieter" {...a11yProps(0)} />
              <Tab label="Geräte/Zähler" {...a11yProps(1)} />
            </Tabs>
          </Box>

          {units?.map((unit, index) => {
            return (
              <Box
                key={"unit-" + index}
                sx={{
                  marginTop: "0.5rem",
                  // borderLeft: '2px solid darkgrey',
                  // borderRight: '2px solid darkgrey',
                  // borderBottom: '2px solid darkgrey',
                  // boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
                  boxShadow:
                    "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
                }}
              >
                <div className="divTableContent">
                  <div className="customer">
                    <strong>{unit.name}</strong>
                  </div>
                  <Box sx={{ flex: 1 }}></Box>
                  <div className="editBtn" onClick={() => updateUnit(unit)}>
                    <EditIcon />
                  </div>

                  <div className="deleteBtn">
                    <DeleteButton
                      item={unit.name}
                      onConfirm={() => onUnitDelete(unit)}
                    />
                  </div>
                </div>

                <CustomTabPanel value={tabValue} index={0}>
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ flex: 1 }}></Box>
                    <Button
                      sx={{ color: "#5f3d3d", borderColor: "#5f3d3d" }}
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={() => onAddTenantButtonClick(unit)}
                    >
                      Neuer Mieter
                    </Button>
                  </Box>

                  <div className=" tenants">
                    {unit.tenants && unit.tenants.length > 0 && (
                      <Box className="tenantTable">
                        <div className="divSubTableContent table-header">
                          <div className="customer">Name</div>
                          <div className="customer">Einzugsdatum</div>
                          <div className="customer">E-Mail</div>
                          <Box sx={{ flex: 1 }}></Box>
                          <div className="editBtn">
                            <strong>Aktionen</strong>
                          </div>
                          <div className="deleteBtn"></div>
                        </div>

                        <Box
                          sx={{
                            padding: "5px",
                            borderBottom: "1px dashed lightgrey",
                            borderLeft: "1px dashed lightgrey",
                            borderRight: "1px dashed lightgrey",
                          }}
                        >
                          {unit.tenants.map((tenant, tenantIndex) => (
                            <div
                              className="divSubTableContent"
                              key={"tenants-" + index + tenantIndex}
                            >
                              <div className="customer">{tenant.name}</div>
                              <div className="customer">10.07.2024</div>
                              <div className="customer">{tenant.id}</div>
                              <Box sx={{ flex: 1 }}></Box>
                              <div className="editBtn">
                                <IconButton
                                  onClick={() =>
                                    onUpdateTenantButtonClick(unit, tenant)
                                  }
                                >
                                  <EditNote></EditNote>
                                </IconButton>{" "}
                                {/* Implement edit functionality */}
                              </div>
                              <div className="deleteBtn">
                                <DeleteButton
                                  Icon={Clear}
                                  item={tenant.name}
                                  onConfirm={() =>
                                    onDeleteTenantButtonClick(tenant, unit)
                                  }
                                />{" "}
                                {/* Implement delete functionality */}
                              </div>
                            </div>
                          ))}
                        </Box>
                      </Box>
                    )}
                  </div>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}>
                  <UnitMeters
                    meters={unit.meters}
                    extIndex={index + ""}
                    onAdd={() => openMeterDialogHandler(unit, undefined)}
                    onEdit={(meter) => openMeterDialogHandler(unit, meter)}
                    onDelete={(meterId) => handleDeleteMeter(unit.id, meterId)}
                  />
                </CustomTabPanel>
              </Box>
            );
          })}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <Stack spacing={2}>
              <Pagination count={10} />
            </Stack>
          </Box>
        </div>
      </div>
    </>
  );
}
