// CommunityProvider.js
import React, { createContext, useContext, useEffect, useState } from 'react';

import Api from '../api/Api';
import Property from '../api/models/Property.model';

interface CommunityContextType {
  community: Property | undefined;
  updateCommunityData: (unit: Property | undefined) => void;
}
const CommunityContext = createContext<CommunityContextType | undefined>(undefined);

export const useCommunity = () => {
  const context = useContext(CommunityContext);
  if (!context) {
    throw new Error('useCommunity must be used within a CommunityProvider');
  }
  return context;
};

interface CommunityProviderProps {
  children: React.ReactNode;
}

export const CommunityProvider: React.FC<CommunityProviderProps> = ({ children }) => {
  const [community, setCommunity] = useState<Property>();

  // useEffect(() => {
  //   Api.getProperty(communityId).then((community) => setCommunity(community));
  // }, [communityId]);

  const updateCommunityData = (data: Property | undefined) => {
    setCommunity(data);
  };

  return (
    <CommunityContext.Provider value={{ community, updateCommunityData }}>
      {children}
    </CommunityContext.Provider>
  );
};
