import { useEffect, useState } from 'react';

import Api from '../../api/Api';
import Settings from '../../api/models/Settings.model';
import { SettingsContext } from './useSettings';
import { Status } from '../../api/models/StoragePosition.model';
import useCustomers from '../customers/useCustomers';

interface SettingsProviderProps {
  children: React.ReactChild;
}

export default function CustomersProvider(props: SettingsProviderProps) {
  const [settings, setSettings] = useState<Settings>(null!);
  const { updateCustomers } = useCustomers();
  // on first load, fetch customers from api
  useEffect(() => {
    Api.getSettings().then((settings) => setSettings(settings));
  }, []);

  // function to reload the customers from api. May be called by consumer elements
  const updateSettings = () => {
    Api.getSettings().then((settings) => {
      updateCustomers();
      setSettings(settings);
    });
  };

  const getStatusFromLevel = (level: number) => {
    const treshholds = settings.ProductThreshholds;
    if (treshholds.inverted) {
      if (level > treshholds.higherTreshhold) {
        return Status.Critical;
      } else if (level < treshholds.lowerTreshhold) {
        return Status.Good;
      }
      return Status.Normal;
    } else {
      if (level < treshholds.lowerTreshhold) {
        return Status.Critical;
      } else if (level > treshholds.higherTreshhold) {
        return Status.Good;
      }
      return Status.Normal;
    }
  };

  return (
    <SettingsContext.Provider value={{ settings, updateSettings, getStatusFromLevel }}>
      {props.children}
    </SettingsContext.Provider>
  );
}
