// LocalityProvider.js
import React, { createContext, useContext, useEffect, useState } from 'react';

import Api from '../api/Api';
import Unit from '../api/models/Unit.model';

interface LocalityContextType {
  locality: Unit | undefined;
  updateLocalityData: (unit: Unit | undefined) => void;
}
const LocalityContext = createContext<LocalityContextType | undefined>(undefined);

export const useLocality = () => {
  const context = useContext(LocalityContext);
  if (!context) {
    throw new Error('useLocality must be used within a LocalityProvider');
  }
  return context;
};

interface LocalityProviderProps {
  children: React.ReactNode;
}

export const LocalityProvider: React.FC<LocalityProviderProps> = ({ children }) => {
  const [locality, setLocality] = useState<Unit>();

  // useEffect(() => {
  //   Api.getUnit(localityId).then((locality) => setLocality(locality));
  // }, [localityId]);

  const updateLocalityData = (data: Unit | undefined) => {
    setLocality(data);
  };

  return (
    <LocalityContext.Provider value={{ locality, updateLocalityData }}>
      {children}
    </LocalityContext.Provider>
  );
};
