import {
  Route,
  RouteObject,
  RouterProvider,
  Routes,
  useParams,
} from "react-router";

import ApiInfo from "../pages/apiInfo/ApiInfo";
import { AuthProvider } from "../contexts/auth/AuthProvider";
import Communities from "../pages/management/Communities";
import CommunityDetails from "../pages/property/Community";
import { CommunityProvider } from "../contexts/CommunityContext";
import Customer from "../pages/customer/Customer";
import Customers from "../pages/management/Customers";
import CustomersProvider from "../contexts/customers/CustomersProvider";
import Hardware from "../pages/management/Hardware";
import { HardwareProvider } from "../contexts/HardwareContext";
import Localities from "../pages/management/Localities";
import Locality from "../pages/property/Locality";
import { LocalityLoader } from "../loaders/LocalityLoader";
import { LocalityProvider } from "../contexts/LocalityContext";
import Location from "../pages/location/Location";
import Locations from "../pages/location/Locations";
import { Login } from "../pages/login/Login";
import MenuWrapper from "../menuWrapper/MenuWrapper";
import Products from "../pages/management/Products";
import Properties from "../pages/management/Properties";
import PropertiesProvider from "../contexts/properties/PropertiesProvider";
import PropertyDetails from "../pages/property/Property";
import { PropertyLoader } from "../loaders/PropertyLoader";
import RequireAuth from "../contexts/auth/RequireAuth";
import Settings from "../pages/settings/Settings";
import SettingsProvider from "../contexts/settings/SettingsProvider";
import Summary from "../pages/summary/Summary";
import { TenantProvider } from "../contexts/TenantContext";
import Tenants from "../pages/management/Tenants";
import TitleProvider from "../contexts/title/TitleProvider";
import Titled from "../contexts/title/Titled";
import Units from "../pages/management/Units";
import { UnitsProvider } from "../contexts/UnitsContext";
import { createBrowserRouter } from "react-router-dom";

// Wrapper component for UnitsProvider
const UnitsProviderWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { propertyId } = useParams<{ propertyId: string }>();
  if (!propertyId) return null; // or handle the case when propertyId is undefined

  return <UnitsProvider propertyId={propertyId}>{children}</UnitsProvider>;
};

const HardwareProviderWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { propertyId } = useParams<{ propertyId: string }>();
  if (!propertyId) return null; // or handle the case when propertyId is undefined

  return (
    <HardwareProvider propertyId={propertyId}>{children}</HardwareProvider>
  );
};

const TenantProviderWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { propertyId } = useParams<{ propertyId: string }>();
  if (!propertyId) return null; // or handle the case when propertyId is undefined

  return <TenantProvider propertyId={propertyId}>{children}</TenantProvider>;
};

export const routes: RouteObject[] = [
  {
    path: "/",
    element: (
      <AuthProvider>
        <RequireAuth>
          <TitleProvider>
            <CustomersProvider>
              <CommunityProvider>
                <LocalityProvider>
                  <PropertiesProvider>
                    <SettingsProvider>
                      <MenuWrapper title="FWF Kundenportal" />
                    </SettingsProvider>
                  </PropertiesProvider>
                </LocalityProvider>
              </CommunityProvider>
            </CustomersProvider>
          </TitleProvider>
        </RequireAuth>
      </AuthProvider>
    ),
    children: [
      {
        path: "communities",
        element: (
          <AuthProvider>
            <RequireAuth>
              <Titled title="Gemeinden">
                <Communities />
              </Titled>
            </RequireAuth>
          </AuthProvider>
        ),
      },
      {
        path: "community/:propertyId",
        element: (
          <AuthProvider>
            <RequireAuth>
              <UnitsProviderWrapper>
                <CommunityDetails />
              </UnitsProviderWrapper>
            </RequireAuth>
          </AuthProvider>
        ),
        loader: PropertyLoader,
      },
      {
        path: "locality/:localityId",
        element: (
          <AuthProvider>
            <RequireAuth>
              <Locality />
            </RequireAuth>
          </AuthProvider>
        ),
        loader: LocalityLoader,
      },

      {
        path: "property/:propertyId",
        element: (
          <AuthProvider>
            <RequireAuth>
              <PropertyDetails />
            </RequireAuth>
          </AuthProvider>
        ),
        loader: PropertyLoader,
        children: [
          {
            path: "units",
            element: (
              <UnitsProviderWrapper>
                <Units />
              </UnitsProviderWrapper>
            ),
          },
          {
            path: "hardware",
            element: (
              <HardwareProviderWrapper>
                <Hardware />
              </HardwareProviderWrapper>
            ),
          },
          {
            path: "tenants",
            element: (
              <TenantProviderWrapper>
                <Tenants />
              </TenantProviderWrapper>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: (
      <AuthProvider>
        <Login />
      </AuthProvider>
    ),
  },
];

const router = createBrowserRouter(routes);

const ContentRouter = () => <RouterProvider router={router} />;

export default ContentRouter;
