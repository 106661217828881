import { Add as AddIcon } from "@mui/icons-material";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import DeleteButton from "../../components/deleteButton/DeleteButton";
import Meter from "../../api/models/Meter.model";

interface MetersProps {
  meters: Meter[];
  extIndex: string;
  onEdit: (meter: Meter) => void;
  onDelete: (meterId: string) => void;
  onAdd: () => void;
}

export default function UnitMeters(props: MetersProps) {
  const { meters, extIndex, onEdit, onDelete, onAdd } = props;

  return (
    <>
      <Button variant="outlined" startIcon={<AddIcon />} onClick={onAdd}>
        Zähler hinzufügen
      </Button>
      <div className=" tenants">
        {meters.length > 0 && (
          <Box
            className="tenantTable"
            sx={{
              padding: "5px",
              borderBottom: "1px dashed lightgrey",
              borderLeft: "1px dashed lightgrey",
              borderRight: "1px dashed lightgrey",
            }}
          >
            <div className="divSubTableContent table-header">
              <div className="customer">
                <strong>Grätenummber</strong>
              </div>
              <Box sx={{ flex: 1 }}></Box>
              <div className="customer">
                <strong>Aktionen</strong>
              </div>
            </div>
            {meters.map((meter, meterIndex) => (
              <div
                className="divSubTableContent"
                key={"meters-" + extIndex + meterIndex}
              >
                <div className="customer">{meter.deviceId}</div>
                <Box sx={{ flex: 1 }}></Box>
                <div className="editBtn">
                  <Button onClick={() => onEdit(meter)}>Edit</Button>
                </div>

                <div className="editBtn">
                  <DeleteButton
                    item={meter.deviceId}
                    onConfirm={() => onDelete(meter.deviceId)}
                  />
                </div>
              </div>
            ))}
          </Box>
        )}
      </div>
    </>
  );
}
