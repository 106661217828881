import * as echarts from "echarts";

import {
  Box,
  Container,
  Pagination,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

type DataPoint = {
  time: string;
  cubicmeter: number;
};

const generateData = (): DataPoint[] => {
  return Array.from({ length: 96 }, (_, i) => {
    const hour = Math.floor(i / 4);
    const minute = (i % 4) * 15;
    const time = `${hour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")}`;

    // Generate water consumption based on time
    let cubicmeter = 0;

    if (hour >= 5 && hour < 12) {
      // Gradually rise from 5 to 12
      cubicmeter =
        (Math.sin(((hour - 5) * Math.PI) / 14) + 1) * 2 +
        (Math.random() - 0.5) * 0.2;
    } else if (hour >= 12 && hour < 16) {
      // Gradually fall from 12 to 16
      cubicmeter =
        (Math.cos(((hour - 12) * Math.PI) / 8) + 1) * 2 +
        (Math.random() - 0.5) * 0.2;
    } else if (hour >= 16 && hour < 20) {
      // Gradually rise again from 16 to 20
      cubicmeter =
        (Math.sin(((hour - 16) * Math.PI) / 8) + 1) * 2 +
        (Math.random() - 0.5) * 0.2;
    } else {
      // Gradually fall from 20 to 5
      const adjustedHour = hour >= 20 ? hour - 20 : hour + 4;
      cubicmeter =
        (Math.cos((adjustedHour * Math.PI) / 9) + 1) * 2 +
        (Math.random() - 0.5) * 0.2;
    }

    return {
      time,
      cubicmeter: parseFloat(cubicmeter.toFixed(2)),
    };
  });
};

const EChartTable: React.FC = () => {
  const contextChartRef = useRef<HTMLDivElement | null>(null);
  const data = useRef<DataPoint[]>(generateData());

  console.log("gen data", data);
  useEffect(() => {
    if (contextChartRef.current) {
      const contextChart = echarts.init(contextChartRef.current);

      const contextOption = {
        grid: {
          left: 0,
          top: 10,
          right: 0,
          bottom: 0,
        },
        xAxis: {
          type: "category",
          data: data.current.map((d) => d.time),
          // boundaryGap: false,
          axisLabel: {
            formatter: (val: string) => "",
          },
        },
        yAxis: {
          type: "value",
          min: 0,
          max: 5,
          axisLabel: {
            formatter: (val: number) => ``,
          },
        },

        series: [
          {
            type: "line",
            data: data.current.map((d) => d.cubicmeter),
            showSymbol: false,
            smooth: false,
          },
        ],
      };

      contextChart.setOption(contextOption);
      // Cleanup chart on unmount
      return () => {
        contextChart.dispose();
      };
    }
  }, []);

  return (
    <>
      {/* <div
        ref={contextChartRef}
        style={{ height: "100px", width: "100%" }}
      ></div> */}

      <TableContainer
        component={Paper}
        sx={{ overflow: "auto", height: "400px" }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Datum</TableCell>
              <TableCell align="right">m³</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.current.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  backgroundColor:
                    index % 2 === 0 ? "action.hover" : "background.paper",
                  "& .MuiTableCell-root": {
                    padding: "4px",
                  },
                }}
              >
                <TableCell>14.07.2024 {row.time}</TableCell>
                <TableCell align="right">{row.cubicmeter} m³</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "1rem",
        }}
      >
        <Stack spacing={2}>
          <Pagination count={10} />
        </Stack>
      </Box>
    </>
  );
};

export default EChartTable;
