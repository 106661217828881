import "../management/customers.scss";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  IconButton,
  Paper,
  Tab,
  Tabs,
  TextField,
  TextFieldProps,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  ChargingStation,
  Map,
  PictureAsPdf,
  Save,
  Search,
  ShowChart,
  TableBar,
  TableRows,
  TextSnippet,
} from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Outlet, useLoaderData, useLocation, useMatch } from "react-router-dom";
import { SetStateAction, SyntheticEvent, useEffect, useState } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DenseDatePicker from "../../components/denseDatepicker/DenseDatePicker";
import EChart from "../../components/chart/EChart";
import EChartTable from "../../components/chart/Table";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import G2Chart from "../../components/chart/G2Chart";
import Property from "../../api/models/Property.model";
import Unit from "../../api/models/Unit.model";
import { useLocality } from "../../contexts/LocalityContext";

export default function Locality() {
  const { unit } = useLoaderData() as { unit: Unit };
  const { pathname } = useLocation();
  const match = useMatch("/property/:propertyId");
  const isOnParentRoute = (match && match.pathname === pathname) || undefined;
  const [isExpanded, setIsExpanded] = useState(true);

  const [value, setValue] = useState<Date | null>(null);
  const [tabValue, setTabValue] = useState(0);

  const { updateLocalityData } = useLocality();

  useEffect(() => {
    updateLocalityData(unit);

    return () => {
      updateLocalityData(undefined);
    };
  }, [unit, updateLocalityData]);

  useEffect(() => {
    if (isOnParentRoute) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [isOnParentRoute, pathname]);

  const handleAccordionChange = (event: SyntheticEvent, expanded: boolean) => {
    setIsExpanded(expanded as SetStateAction<boolean>);
  };

  const DenseTextField = (props: TextFieldProps) => (
    <TextField
      {...props}
      InputProps={{
        ...props.InputProps,
        sx: { padding: "4px 8px" },
      }}
      InputLabelProps={{
        ...props.InputLabelProps,
        sx: { fontSize: "0.875rem" },
      }}
      sx={{
        "& .MuiInputBase-root": {
          height: "32px",
        },
      }}
    />
  );

  /**** TABS */

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
      </div>
    );
  }

  return (
    <>
      <Box sx={{ marginTop: "-1rem" }}>
        <Toolbar disableGutters>
          <IconButton>
            <PictureAsPdf />
          </IconButton>
          <IconButton>
            <Save />
          </IconButton>
          <IconButton>
            <TextSnippet />
          </IconButton>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DenseDatePicker />
          </LocalizationProvider>
          <IconButton>
            <ShowChart />
          </IconButton>
          <IconButton>
            <TableRows />
          </IconButton>
          <IconButton>
            <Map />
          </IconButton>
        </Toolbar>

        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            sx={{
              "& .MuiTab-root": {
                color: "#5f3d3d", // Default tab text color
                "&.Mui-selected": {
                  color: "#5f3d3d", // Selected tab text color
                },
              },
              "& .MuiTabs-indicator": {
                backgroundColor: "#5f3d3d", // Indicator color
              },
            }}
          >
            <Tab label="Diagramm" {...a11yProps(0)} />
            <Tab label="Tabellarisch" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={0}>
          <EChart />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <EChartTable />
        </CustomTabPanel>
      </Box>
    </>
  );
}
