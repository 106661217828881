import "./products.scss";

import { Add as AddIcon, Apartment } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import Property, { PropertyCreateInput } from "../../api/models/Property.model";
import { useCallback, useState } from "react";

import Api from "../../api/Api";
import CreatePropertyDialog from "../../components/dialog/createPropertyDialog";
import DeleteButton from "../../components/deleteButton/DeleteButton";
import EditIcon from "@mui/icons-material/Edit";
import { Outlet } from "react-router";
import useProperties from "../../contexts/properties/useProperties";

export default function Communities() {
  const { properties, updateProperties } = useProperties();
  const [currentProperty, setCurrentProperty] = useState<
    Property | undefined
  >();

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const onDialogClose = useCallback(() => {
    setOpenDialog(false);
  }, []);

  const onAddPropertyButtonClick = useCallback(() => {
    setCurrentProperty(undefined);
    setOpenDialog(true);
  }, []);

  const onDialogSubmit = useCallback(
    (data: PropertyCreateInput) => {
      if (data.id) {
        const tempUpdate = {
          number: data.number,
          name: data.name,
        };
        updateCurrentProperty(tempUpdate, data.id);
      } else {
        const temp = {
          number: data.number,
          name: data.name,
          // id: data.id, // ???
        };
        addNewProperty(temp);
      }
    },
    [updateProperties]
  );

  const updateCurrentProperty = (newProperty: any, id: string) => {
    Api.updateProperty(newProperty, id).then(() => {
      updateProperties();
    });
  };

  const addNewProperty = (newProduct: any) => {
    Api.postProperty(newProduct).then(() => {
      updateProperties();
    });
  };

  const updateProperty = useCallback((property: Property) => {
    setCurrentProperty(property);
    setOpenDialog(true);
  }, []);

  const onPropertyDelete = (property: Property) => {
    Api.deleteProperty(property).then(() => {
      updateProperties();
    });
  };

  return (
    <>
      <div className="contentTable">
        <div className="header">
          <div className="cell1">
            <Apartment id="categoryIcon" />
            Gemeinden
          </div>
          <Button
            className="newStorageUnitBtn"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => onAddPropertyButtonClick()}
          >
            Neue Gemeinde anlegen
          </Button>
        </div>
        <Box className="divTable" sx={{ marginTop: "1rem" }}>
          <CreatePropertyDialog
            open={openDialog}
            onClose={onDialogClose}
            onSubmit={onDialogSubmit}
            property={currentProperty}
          />
          <div className="divTableContent table-header">
            <div className="customer">
              <strong>Gemeinde</strong>
            </div>

            <Box sx={{ flex: 1 }}></Box>
            <div className="editBtn">
              <strong>Aktionen</strong>
            </div>
            <div className="deleteBtn"></div>
          </div>
          {properties?.map((property, index) => {
            return (
              <div className="divTableContent" key={index}>
                <div className="customer">{property.name}</div>

                <Box sx={{ flex: 1 }}></Box>
                <div
                  className="editBtn"
                  onClick={() => updateProperty(property)}
                >
                  <EditIcon />
                </div>
                <div className="deleteBtn">
                  <DeleteButton
                    item={property.name}
                    onConfirm={() => onPropertyDelete(property)}
                  />
                </div>
              </div>
            );
          })}
        </Box>
      </div>
    </>
  );
}
