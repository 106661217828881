import Api from '../api/Api';
import { LoaderFunctionArgs } from 'react-router-dom';
import Property from '../api/models/Property.model';
import Unit from '../api/models/Unit.model';

export async function LocalityLoader({ params }: LoaderFunctionArgs): Promise<{ unit: Unit }> {
  const { localityId } = params;

  console.log('localityId', localityId);
  // TODO handle propertyId undefined

  const unit = await Api.getUnit(localityId as string);

  // const unit = new Unit('66a38d0fc44828aad70d332b', 'ERMBSHOF');
  return { unit };
}
