import "./login.scss";

import { Box, Button, Paper, TextField } from "@mui/material";

import ErrorIcon from "@mui/icons-material/Error";
import useAuth from "../../contexts/auth/useAuth";
import { useNavigate } from "react-router";
import { useState } from "react";

export function Login() {
  const [userMail, setUserMail] = useState("");
  const [password, setPassword] = useState("");

  const [loginFailed, setLoginFailed] = useState(false);

  const navigate = useNavigate();
  const auth = useAuth();

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      sendLogin();
    }
  };

  const sendLogin = () => {
    auth.login(userMail, password, onLoginSuccess, onLoginFail);
  };

  const onLoginSuccess = () => {
    setLoginFailed(false);
    navigate("/communities");
  };

  const onLoginFail = () => {
    setLoginFailed(true);
  };

  const [isCaps, setIsCaps] = useState(false);
  document.addEventListener("keydown", function (event) {
    var caps = event.getModifierState && event.getModifierState("CapsLock");
    setIsCaps(caps);
  });

  return (
    <div className="loginMain">
      <div className="loginLeft">
        <div className="bg"></div>
        <div className="bg bg2"></div>
        <div className="bg bg3"></div>
      </div>
      <div className="loginRight"></div>
      <div className="loginFormContainer">
        <div className="loginFormLeft">
          <span className="StoreMonText">Kundenportal</span>
        </div>
        <div className="loginFormRight">
          <Box>Frisches Wasser für Franken</Box>
          <img
            src="fwf-logo.png"
            alt="Fernwasserwerke Franken"
            className="loginLogo"
          />
          <Paper component="form" className="loginForm" elevation={3}>
            <span className="loginHeaderText">Willkommen Zurück!</span>
            <span className="loginHeaderUnderline">Login um Fortzufahren</span>
            {loginFailed && (
              <span className="loginErrorText">
                <ErrorIcon className="errorIcon" />
                Falscher Benutzername oder falsches Passwort
              </span>
            )}
            <TextField
              className="loginFormText"
              margin="normal"
              variant="standard"
              label="E-Mail"
              color="error"
              autoComplete="current-username"
              onChange={(event) => setUserMail(event.target.value)}
            />
            <TextField
              className="loginFormText"
              margin="normal"
              variant="standard"
              label="Passwort"
              type="password"
              color="error"
              id="password"
              autoComplete="current-password"
              onChange={(event) => setPassword(event.target.value)}
              onKeyDown={(event) => handleKeyDown(event.nativeEvent)}
            />
            <Button
              variant="contained"
              className="loginButton"
              onClick={sendLogin}
            >
              Login
            </Button>
            {isCaps && (
              <span className="isCapsText">
                <ErrorIcon className="errorIcon" />
                Feststelltaste ist aktiv
              </span>
            )}
          </Paper>
        </div>
      </div>
    </div>
  );
}
