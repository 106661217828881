import Customer, { CustomerCreateInput } from "./models/Customer.model";
import Meter, { MeterCreateInput } from "./models/Meter.model";
import Product, { ProductCreateInput } from "./models/Product.model";
import Property, { PropertyCreateInput } from "./models/Property.model";
import Sensor, { SensorCreateInput } from "./models/Sensor.model";
import StorageLocation, {
  StorageLocationCreateInput,
  StorageLocationUpdateInput,
} from "./models/StorageLocation.model";
import StorageUnit, {
  StorageUnitCreateInput,
} from "./models/StorageUnit.model";
import Tenant, { TenantCreateInput } from "./models/Tenant.model";
import Unit, { UnitCreateInput } from "./models/Unit.model";
import axios, { AxiosResponse } from "axios";

import { ApiResource } from "./ApiResource";
import CriticalProducts from "./models/CriticalProducts.model";
import StoragePosition from "./models/StoragePosition.model";
import UnitProductAmount from "./models/UnitProductAmount.model";
import { readAccessToken } from "../contexts/auth/AuthProvider";

//TODO hookify this class (maybe make it a context?)
//TODO implement error handling
// TODO make class more general to handle all CRUD request swith generics (see angular iotdm crud.service))
export default class Api {
  private readonly DO_LOGGING = true;
  //@ts-ignore
  get API_URL() {
    //@ts-ignore
    console.log(window.globalConfig);

    // generating the api url from the location of the website.
    // This assumes that the API and the website are hosted on the same domain.
    if (process.env.REACT_APP_API_PORT) {
      // DEV
      return `${document.location.protocol}//${document.location.hostname}:${process.env.REACT_APP_API_PORT}/api/`;
    }

    // PROD env
    return `${document.location.protocol}//${document.location.hostname}/api/`;
  }

  get access_token() {
    return readAccessToken();
  }

  get authHeader() {
    return { Authorization: "Bearer " + this.access_token };
  }

  // singleton implementation
  private static _instance: Api;
  static get instance(): Api {
    if (!this._instance) {
      this._instance = new Api();
    }

    return this._instance;
  }

  private constructor() {}

  private static delay() {
    return new Promise((resolve) => setTimeout(resolve, Math.random() * 1000));
  }

  private async get<T>(resource: string): Promise<T> {
    if (this.DO_LOGGING) {
      console.log("🔹 GET: " + resource);
    }

    const response = await axios.get<T>(this.API_URL + resource, {
      headers: this.authHeader,
    }); //TODO we need to catch promise rejection here!

    if (this.DO_LOGGING) {
      console.groupCollapsed("🔸 GET: " + resource);
      console.log(response);
      console.groupEnd();
    }

    return response.data;
  }

  private async post<T>(resource: string, data: Partial<T>): Promise<T> {
    if (this.DO_LOGGING) {
      console.groupCollapsed("🔹 POST: " + resource);
      console.log(data);
      console.groupEnd();
    }

    const response = await axios.post<T>(this.API_URL + resource, data, {
      headers: this.authHeader,
    }); //TODO we need to catch promise rejection here!

    if (this.DO_LOGGING) {
      console.groupCollapsed("🔸 POST: " + resource);
      console.log(response);
      console.groupEnd();
    }

    return response.data;
  }

  private async put(resource: string, data: any): Promise<any> {
    if (this.DO_LOGGING) {
      console.groupCollapsed("🔹 PUT: " + resource);
      console.log(data);
      console.groupEnd();
    }

    const response = await axios.put(this.API_URL + resource, data, {
      headers: this.authHeader,
    });

    if (this.DO_LOGGING) {
      console.groupCollapsed("🔸 PUT: " + resource);
      console.log(response);
      console.groupEnd();
    }

    return response.data;
  }

  public async delete(resource: string): Promise<any> {
    if (this.DO_LOGGING) {
      console.groupCollapsed("🔹 DELETE: " + resource);
      console.groupEnd();
    }

    const response = await axios.delete(this.API_URL + resource, {
      headers: this.authHeader,
    });

    if (this.DO_LOGGING) {
      console.groupCollapsed("🔸 DELETE: " + resource);
      console.log(response);
      console.groupEnd();
    }
  }

  public static async sendLogin(
    userMail: string,
    password: string
  ): Promise<AxiosResponse<{ access_token: string }>> {
    return await axios
      .post<{ access_token: string }>(
        this.instance.API_URL + ApiResource.login,
        {
          email: userMail,
          password: password,
        }
      )
      .then((response) => {
        return response;
      });
  }

  public static async getProperties(): Promise<Property[]> {
    return this.instance.get<Property[]>(ApiResource.properties);
  }

  public static async getProperty(id: string): Promise<Property> {
    return this.instance.get(`${ApiResource.properties}/${id}`);
  }

  public static async postProperty(
    newProperty: PropertyCreateInput
  ): Promise<Property> {
    return this.instance.post<Property>(ApiResource.properties, newProperty);
  }

  public static async updateProperty(
    newProperty: Property,
    id: string
  ): Promise<Property> {
    return this.instance.put(`${ApiResource.properties}/${id}`, newProperty);
  }

  public static async deleteProperty(property: Property): Promise<any> {
    return this.instance.delete(`${ApiResource.properties}/${property.id}`);
  }

  // UNITS
  public static async getUnits(propertyId: string): Promise<Unit[]> {
    return this.instance.get(`${ApiResource.properties}/${propertyId}/units`);
  }

  public static async postPropertyUnit(
    propertyId: string,
    newUnit: UnitCreateInput
  ): Promise<Unit> {
    return this.instance.post<Unit>(
      `${ApiResource.properties}/${propertyId}/units`,
      newUnit
    );
  }

  public static async getUnit(unitId: string): Promise<Unit> {
    return this.instance.get(`${ApiResource.units}/${unitId}`);
  }

  public static async updateUnit(updateUnit: Unit, id: string): Promise<Unit> {
    return this.instance.put(`${ApiResource.units}/${id}`, updateUnit);
  }

  public static async deleteUnit(unit: Unit): Promise<any> {
    return this.instance.delete(`${ApiResource.units}/${unit.id}`);
  }

  // TENANTS
  public static async getPropertyTenants(propertyd: string): Promise<Tenant[]> {
    return this.instance.get(`${ApiResource.properties}/${propertyd}/tenants`);
  }
  public static async getUnitTenants(unitId: string): Promise<Tenant[]> {
    return this.instance.get(`${ApiResource.units}/${unitId}/tenants`);
  }

  public static async postUnitTenant(
    unitId: string,
    newTenant: TenantCreateInput
  ): Promise<Tenant> {
    return this.instance.post<Tenant>(
      `${ApiResource.units}/${unitId}/tenants`,
      newTenant
    );
  }

  public static async updateTenant(
    updateTenant: Tenant,
    id: string
  ): Promise<Tenant> {
    return this.instance.put(`${ApiResource.tenants}/${id}`, updateTenant);
  }

  public static async deleteTenant(tenant: Tenant): Promise<any> {
    return this.instance.delete(`${ApiResource.tenants}/${tenant.id}`);
  }

  // METERS
  public static async getPropertyMeters(propertyId: string): Promise<Meter[]> {
    return this.instance.get(`${ApiResource.properties}/${propertyId}/meters`);
  }

  public static async postPropertyMeter(
    propertyId: string,
    newMeter: MeterCreateInput
  ): Promise<Meter> {
    return this.instance.post<Meter>(
      `${ApiResource.properties}/${propertyId}/meters`,
      newMeter
    );
  }

  public static async getUnitMeters(unitId: string): Promise<Meter[]> {
    return this.instance.get(`${ApiResource.units}/${unitId}/meters`);
  }

  public static async postUnitMeter(
    unitId: string,
    newMeter: MeterCreateInput
  ): Promise<Meter> {
    return this.instance.post<Meter>(
      `${ApiResource.units}/${unitId}/meters`,
      newMeter
    );
  }

  public static async updateMeter(
    updateMeter: Meter,
    id: string
  ): Promise<Meter> {
    return this.instance.put(`${ApiResource.meters}/${id}`, updateMeter);
  }

  public static async deleteMeter(tenant: Meter): Promise<any> {
    return this.instance.delete(`${ApiResource.meters}/${tenant.id}`);
  }

  /// ******* OLDD following *****

  public static async getCustomers(): Promise<Customer[]> {
    return []; // this.instance.get<Customer[]>(ApiResource.customers);
  }

  public static async getCustomer(customerId: number): Promise<Customer> {
    return this.instance.get<Customer>(ApiResource.customer(customerId));
  }

  public static async postCustomer(newCustomer: CustomerCreateInput) {
    return this.instance.post<Customer>(ApiResource.customers, newCustomer);
  }

  public static async deleteCustomer(customer: Customer): Promise<any> {
    return this.instance.delete(ApiResource.customer(customer.id));
  }

  public static async getProducts(): Promise<Product[]> {
    // this.instance.get<Product[]>(ApiResource.products);
    return [];
  }

  public static async postProduct(
    newProduct: ProductCreateInput
  ): Promise<Product> {
    return this.instance.post<Product>(ApiResource.products, newProduct);
  }
  public static async updateProduct(
    newProduct: Product,
    id: number
  ): Promise<Product> {
    return this.instance.put(
      `${ApiResource.products}/${id}/update`,
      newProduct
    );
  }

  public static async deleteProduct(product: Product): Promise<any> {
    return this.instance.delete(ApiResource.product(product.id));
  }

  public static async getStorageLocationWithUnits(
    locationId: number
  ): Promise<StorageLocation> {
    return this.instance.get<StorageLocation>(
      ApiResource.storageLocation(locationId)
    );
  }
  public static async getProductAmountForUnits(
    locationId: number
  ): Promise<UnitProductAmount[]> {
    return this.instance.get<UnitProductAmount[]>(
      ApiResource.storageLocation(locationId) + "/products"
    );
  }

  public static async getStorageLocationCritical(
    locationId: number
  ): Promise<CriticalProducts[]> {
    return this.instance.get<CriticalProducts[]>(
      ApiResource.storageLocation(locationId) + "/critical"
    );
  }

  public static async postStorageUnit(
    locationId: number,
    newStorage: StorageUnitCreateInput
  ) {
    return this.instance.post<StorageUnit>(
      ApiResource.storageLocationUnit(locationId),
      newStorage
    );
  }

  public static async deleteStorageUnit(storageUnitId: number) {
    return this.instance.delete(ApiResource.storageUnit(storageUnitId));
  }

  public static async getSensors(customerId: number): Promise<Sensor[]> {
    return this.instance.get<Sensor[]>(ApiResource.customerSensors(customerId));
  }

  public static async getUnassignedSensors(): Promise<Sensor[]> {
    return this.instance.get<Sensor[]>(ApiResource.unassignedSensors);
  }

  public static async putSensor(
    locationId: number,
    newSensor: Sensor
  ): Promise<Sensor> {
    console.log("updated Sensor: " + JSON.stringify(newSensor));
    await this.delay();
    return newSensor;
  }

  public static async postSensor(newSensor: SensorCreateInput) {
    return this.instance.post<Sensor>(ApiResource.sensors, newSensor);
  }

  public static async getCustomerLocations(
    customerId: number
  ): Promise<StorageLocation[]> {
    return this.instance.get<StorageLocation[]>(
      ApiResource.customerLocations(customerId)
    );
  }

  public static async postCustomerLocation(
    customerId: number,
    newLocation: StorageLocationCreateInput
  ): Promise<StorageLocationCreateInput> {
    return this.instance.post<StorageLocationCreateInput>(
      ApiResource.customerLocations(customerId),
      newLocation
    );
  }
  public static async putCustomerLocation(
    customerId: number,
    location: StorageLocationUpdateInput
  ): Promise<StorageLocation> {
    return this.instance.put(
      ApiResource.customerLocations(customerId) + "/update",
      location
    );
  }

  public static async deleteLocation(location: StorageLocation) {
    return this.instance.delete(ApiResource.storageLocation(location.id));
  }

  public static async putProductOfStoragePositions(
    product: Product,
    storagePositionIds: number[]
  ): Promise<StoragePosition[]> {
    return Promise.all(
      storagePositionIds.map((storagePositionId) =>
        this.instance.put(
          ApiResource.positionProduct(storagePositionId),
          product
        )
      )
    );
  }

  public static async putLevelOfStoragePosition(
    storagePositionId: number,
    level: number
  ) {
    return this.instance.put(ApiResource.positionLevel(storagePositionId), {
      level: level,
    });
  }

  public static async removeProductOfStoragePositions(
    storagePositionIds: number[]
  ): Promise<StoragePosition[]> {
    return Promise.all(
      storagePositionIds.map((storagePositionId) =>
        this.instance.put(ApiResource.positionProduct(storagePositionId), null)
      )
    );
  }
  public static async getSettings(): Promise<any> {
    return []; // this.instance.get(ApiResource.settings);
  }
  public static async updateSettings(
    settingsId: number,
    settings: any
  ): Promise<any> {
    return this.instance.put(
      ApiResource.setting(settingsId) + "/update",
      settings
    );
  }

  public static async addTreshold(
    userId: number,
    treshhold: any
  ): Promise<any> {
    return this.instance.post(ApiResource.settingTreshhold(userId), treshhold);
  }
}
