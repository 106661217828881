import * as echarts from "echarts";

import {
  Box,
  Container,
  Pagination,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

type DataPoint = {
  time: string;
  cubicmeter: number;
};

const generateData = (): DataPoint[] => {
  return Array.from({ length: 96 }, (_, i) => {
    const hour = Math.floor(i / 4);
    const minute = (i % 4) * 15;
    const time = `${hour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")}`;

    // Generate water consumption based on time
    let cubicmeter = 0;

    if (hour >= 5 && hour < 12) {
      // Gradually rise from 5 to 12
      cubicmeter =
        (Math.sin(((hour - 5) * Math.PI) / 14) + 1) * 2 +
        (Math.random() - 0.5) * 0.2;
    } else if (hour >= 12 && hour < 16) {
      // Gradually fall from 12 to 16
      cubicmeter =
        (Math.cos(((hour - 12) * Math.PI) / 8) + 1) * 2 +
        (Math.random() - 0.5) * 0.2;
    } else if (hour >= 16 && hour < 20) {
      // Gradually rise again from 16 to 20
      cubicmeter =
        (Math.sin(((hour - 16) * Math.PI) / 8) + 1) * 2 +
        (Math.random() - 0.5) * 0.2;
    } else {
      // Gradually fall from 20 to 5
      const adjustedHour = hour >= 20 ? hour - 20 : hour + 4;
      cubicmeter =
        (Math.cos((adjustedHour * Math.PI) / 9) + 1) * 2 +
        (Math.random() - 0.5) * 0.2;
    }

    return {
      time,
      cubicmeter: parseFloat(cubicmeter.toFixed(2)),
    };
  });
};

const EChart: React.FC = () => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  const data = useRef<DataPoint[]>(generateData());

  console.log("gen data", data);
  useEffect(() => {
    if (chartRef.current) {
      const chart = echarts.init(chartRef.current);

      const option: echarts.EChartsOption = {
        grid: {
          left: 50,
          top: 10,
          right: 10,
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params: any) {
            const param = params[0];
            return `${(param as any).axisValueLabel}<br/>${
              (param as any).value
            } m³`;
          },
        },
        xAxis: {
          type: "category",
          data: data.current.map((d) => d.time),
          boundaryGap: false,
          axisLabel: {
            formatter: (val: string) => {
              const [hour, minute] = val.split(":");
              return minute === "00"
                ? `${hour}:00`
                : minute === "30"
                ? `${hour}:30`
                : "";
            },
          },
          splitLine: {
            show: true,
            interval: (index, value) => {
              const [hour, minute] = value.split(":");
              return minute === "00" || minute === "30";
            },
            lineStyle: {
              type: "solid",
            },
          },
        },
        yAxis: {
          type: "value",
          min: 0,
          max: 5,
          axisLabel: {
            formatter: (val: number) => `${val.toFixed(2)} m³`,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
            xAxisIndex: 0,
            start: 0,
            end: 100, // Initially show the full range
          },
          {
            type: "slider",
            xAxisIndex: 0,
            start: 0,
            end: 100, // Initially show the full range
          },
        ],
        series: [
          {
            type: "line",
            data: data.current.map((d) => d.cubicmeter),
            showSymbol: false,
            smooth: false, // Set smooth to false
            lineStyle: {
              width: 1, // Set the line width here
            },
          },
        ],
      };

      chart.setOption(option);

      // Cleanup chart on unmount
      return () => {
        chart.dispose();
      };
    }
  }, []);

  return (
    <Box
      sx={
        {
          // borderLeft: '1px dashed #245b94',
          // borderBottom: '1px dashed #245b94',
          // borderRight: '1px dashed #245b94',
          // borderBottomLeftRadius: '5px',
          // borderBottomRightRadius: '5px',
        }
      }
      ref={chartRef}
      style={{ height: "400px", width: "100%" }}
    ></Box>
  );
};

export default EChart;
