import { useEffect, useState } from 'react';

import Api from '../../api/Api';
import { PropertiesContext } from './useProperties';
import Property from '../../api/models/Property.model';

interface PropertyProviderProps {
  children: React.ReactChild;
}

export default function PropertiesProvider(props: PropertyProviderProps) {
  const [properties, setProperties] = useState<Property[]>(null!);
  // const { updateCustomers } = useCustomers();

  // on first load, fetch customers from api
  useEffect(() => {
    Api.getProperties().then((properties) => setProperties(properties));
  }, []);

  // function to reload the customers from api. May be called by consumer elements
  const updateProperties = () => {
    console.log('PROpErties provider:  Update properties called');
    Api.getProperties().then((properties) => {
      // updateCustomers();
      setProperties(properties);
    });
  };

  return (
    <PropertiesContext.Provider value={{ properties, updateProperties }}>
      {props.children}
    </PropertiesContext.Provider>
  );
}
