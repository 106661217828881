import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField, TextFieldProps } from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import React from "react";

const DenseDatePicker: React.FC = () => {
  const [value, setValue] = React.useState<Dayjs | null>(null);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Datum"
        value={value}
        onChange={(newValue) => setValue(newValue)}
        slotProps={{ textField: { size: "small" } }}
      />
    </LocalizationProvider>
  );
};

export default DenseDatePicker;
