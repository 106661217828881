import { useEffect, useState } from 'react';

import Api from '../../api/Api';
import Customer from '../../api/models/Customer.model';
import { CustomersContext } from './useCustomers';

interface CustomerProviderProps {
  children: React.ReactNode;
}

export default function CustomersProvider(props: CustomerProviderProps) {
  const [customers, setCustomers] = useState<Customer[]>(null!);

  // on first load, fetch customers from api
  useEffect(() => {
    Api.getCustomers().then((customers) => setCustomers(customers));
  }, []);

  // function to reload the customers from api. May be called by consumer elements
  const updateCustomers = () => {
    Api.getCustomers().then((customers) => setCustomers(customers));
  };

  return (
    <CustomersContext.Provider value={{ customers, updateCustomers }}>
      {props.children}
    </CustomersContext.Provider>
  );
}
