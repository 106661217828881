import "./createStorageSegmentDialog.scss";

import ConfirmationDialog, {
  ConfirmationDialogProps,
} from "./ConfirmationDialog";
import Unit, { UnitCreateInput } from "../../api/models/Unit.model";
import { useCallback, useEffect, useReducer, useState } from "react";

import { Box } from "@mui/material";
import TextInput from "../input/TextInput";

interface CreateUnitDialogProps {
  onSubmit?: (data: UnitCreateInput) => void;
  unit?: Unit;
}

export default function CreateUnitDialog(
  props: CreateUnitDialogProps & ConfirmationDialogProps
) {
  const { onSubmit, unit } = props;
  const [formState, setFormState] = useState<UnitCreateInput>(null!);

  const reducer = (
    state: Map<string, boolean>,
    action: { type: "add" | "remove" | "reset"; name: string }
  ) => {
    switch (action.type) {
      case "add":
        state.set(action.name, true);
        break;
      case "remove":
        state.set(action.name, false);
        break;
      case "reset":
        state = new Map<string, boolean>();
    }

    return state;
  };

  // this error state is a map that maps the input field names to their error state.
  // only when all values of this map are false the form is error free
  const [errors, dispatch] = useReducer(reducer, new Map<string, boolean>());

  const hasErrors = useCallback(() => {
    const iterator = errors.entries();
    let current = iterator.next();

    while (!current.done) {
      // current.value is an array containing the key and value of the current map entry
      // we want to check the value, so we use the 2nd element of that array (with index 1)
      if (current.value[1]) {
        return true;
      }

      current = iterator.next();
    }

    return false;
  }, [errors]);

  const onSubmitConfirmation = useCallback(() => {
    onSubmit?.(formState);
    dispatch({ type: "reset", name: "" });
  }, [formState, onSubmit]);

  const onFormChange = useCallback((state: UnitCreateInput) => {
    setFormState(state);
  }, []);

  return (
    <ConfirmationDialog
      title="Neue Ortschaft"
      onAccept={() => onSubmitConfirmation()}
      confirmDisabled={hasErrors()}
      onAbort={() => dispatch({ type: "reset", name: "" })}
      {...props}
    >
      <CreateUnitSegmentForm
        onChange={onFormChange}
        dispatch={dispatch}
        unit={unit}
      />
    </ConfirmationDialog>
  );
}

interface CreateUnitFormProps {
  dispatch: React.Dispatch<{ type: "add" | "remove" | "reset"; name: string }>;
  onChange?: (state: UnitCreateInput) => void;
  unit?: Unit;
}

function CreateUnitSegmentForm(props: CreateUnitFormProps) {
  const { dispatch, onChange, unit } = props;
  const [formState, setFormState] = useState<UnitCreateInput>({
    name: "",
  });

  useEffect(() => {
    onChange?.(formState);
  }, [onChange, formState]);
  useEffect(() => {
    if (unit) {
      setFormState({
        name: unit.name,

        id: unit.id,
      });
    }
  }, []);

  const onErrorStateChange = useCallback(
    (error: boolean, name: string) => {
      if (error) {
        dispatch({ type: "add", name: name });
      } else {
        dispatch({ type: "remove", name: name });
      }
    },
    [dispatch]
  );

  const onNameChange = useCallback((newName: string) => {
    setFormState((currentState) => ({ ...currentState, name: newName }));
  }, []);

  return (
    <Box className="allStorageSegmentInputsBox">
      <Box
        className="storageUnitNameBox"
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "1rem",
          paddingRight: "2rem",
          width: "100%",
        }}
      >
        <div>
          <TextInput
            className="storageSegmentTextInput"
            label="Name der Ortschaft"
            startValue={formState.name}
            onChange={onNameChange}
            onErrorStateChange={(error) => onErrorStateChange(error, "name")}
            sx={{
              height: "100%",
              display: "flex",
              marginTop: "5rem",
            }}
          />
        </div>
      </Box>
    </Box>
  );
}
