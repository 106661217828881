import "../management/customers.scss";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Paper,
} from "@mui/material";
import {
  Add as AddIcon,
  Clear,
  ClearAll,
  EditNote,
  LocationCity,
  MeetingRoom,
  RemoveCircleOutline,
} from "@mui/icons-material";
import { Outlet, useLoaderData, useLocation, useMatch } from "react-router-dom";
import {
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import Unit, { UnitCreateInput } from "../../api/models/Unit.model";

import Api from "../../api/Api";
import CreateUnitDialog from "../../components/dialog/createUnitDialog";
import DeleteButton from "../../components/deleteButton/DeleteButton";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Property from "../../api/models/Property.model";
import { useCommunity } from "../../contexts/CommunityContext";
import useProperties from "../../contexts/properties/useProperties";
import { useUnits } from "../../contexts/UnitsContext";

export default function CommunityDetails() {
  const { property } = useLoaderData() as { property: Property };

  const { pathname } = useLocation();
  const match = useMatch("/property/:propertyId");
  const isOnParentRoute = (match && match.pathname === pathname) || undefined;
  const [isExpanded, setIsExpanded] = useState(true);
  const [updateData, setUpdateData] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { units, updateUnits, updateUnitTenants, handleTenantAction } =
    useUnits();

  const { updateProperties } = useProperties();
  const [currentUnit, setCurrentUnit] = useState<Unit | undefined>();

  const { updateCommunityData } = useCommunity();

  useEffect(() => {
    updateCommunityData(property);

    return () => {
      updateCommunityData(undefined);
    };
  }, [property, updateCommunityData]);

  useEffect(() => {
    if (isOnParentRoute) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [isOnParentRoute, pathname]);

  const onDialogSubmit = useCallback(
    (data: UnitCreateInput) => {
      if (data.id) {
        const updateData = {
          // TODO Map other properties of Unit for saving later
          name: data.name,
        };

        updateCurrentUnit(updateData, data.id);
      } else {
        const newData = {
          name: data.name,
          // id: data.id, // ???
        };
        addNew(property.id as string, newData);
      }
    },
    [updateUnits, property]
  );

  const updateCurrentUnit = (newProperty: any, id: string) => {
    Api.updateUnit(newProperty, id).then(() => {
      updateUnits();
      updateProperties();
    });
  };

  const addNew = (propertyId: string, newUnit: any) => {
    Api.postPropertyUnit(propertyId, newUnit).then(() => {
      updateUnits();

      updateProperties();
    });
  };

  const onUnitDelete = (unit: Unit) => {
    Api.deleteUnit(unit).then(() => {
      updateUnits();
      updateProperties();
    });
  };

  const updateUnit = useCallback((unit: Unit) => {
    setCurrentUnit(unit);
    setOpenDialog(true);
  }, []);

  const onAddButtonClick = useCallback(() => {
    setCurrentUnit(undefined);
    setOpenDialog(true);
  }, []);

  const onDialogClose = useCallback(() => {
    setOpenDialog(false);
  }, []);

  const handleAccordionChange = (event: SyntheticEvent, expanded: boolean) => {
    setIsExpanded(expanded as SetStateAction<boolean>);
  };

  console.log("------------ ", property, "in", pathname, match);
  return (
    <>
      {
        <Box>
          <Box className="propertyDetails">
            <Accordion expanded={isExpanded} onChange={handleAccordionChange}>
              <AccordionSummary
                sx={{ backgroundColor: "#245b94", color: "white" }}
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box
                  sx={{
                    p: 1,
                    fontSize: "15pt",
                  }}
                >
                  {property.name}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ padding: "1rem" }}>
                  <Box>
                    <strong>Fläche:</strong>
                  </Box>
                  <Box>22,92 km2</Box>
                </Box>

                <Box sx={{ padding: "1rem" }}>
                  <Box>
                    <strong>Bevölkerung:</strong>
                  </Box>
                  <Box>817 (31. Dez. 2008)</Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>

          <div className="note">
            TODO: Liegenschaft-Eigenschaften einklappen, wenn auf sub-route
          </div>

          <div className="contentTable">
            <div className="header">
              <div className="cell1">
                <LocationCity id="categoryIcon" />
                Ortschaften
              </div>
              <Button
                className="newStorageUnitBtn"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => onAddButtonClick()}
              >
                Neue Ortschaft hinzufügen
              </Button>
            </div>

            <div className="table">
              {/* <Outlet context={{ property }} /> */}
              <CreateUnitDialog
                open={openDialog}
                onClose={onDialogClose}
                onSubmit={onDialogSubmit}
                unit={currentUnit}
              />
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                {units?.map((unit, index) => {
                  return (
                    <Box
                      key={"unit-" + index}
                      sx={
                        {
                          // marginTop: '0.5rem',
                          // borderLeft: '2px solid darkgrey',
                          // borderRight: '2px solid darkgrey',
                          // borderBottom: '2px solid darkgrey',
                          // boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
                          // boxShadow:
                          //   'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px',
                        }
                      }
                    >
                      <div className="divTableContent">
                        <div className="customer">
                          <strong>{unit.name}</strong>
                        </div>
                        <Box sx={{ flex: 1 }}></Box>
                        <div
                          className="editBtn"
                          onClick={() => updateUnit(unit)}
                        >
                          <EditIcon />
                        </div>

                        <div className="deleteBtn">
                          <DeleteButton
                            item={unit.name}
                            onConfirm={() => onUnitDelete(unit)}
                          />
                        </div>
                      </div>
                    </Box>
                  );
                })}
              </Box>
            </div>
          </div>
        </Box>
      }
    </>
  );
}
